h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 24px;
}

/* form {
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

label {
  display: block;
  /* margin-bottom: 0.5rem; */
}

button[type="submit"],
button[type="button"] {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button[type="submit"]:hover,
button[type="button"]:hover {
  background-color: #0069d9;
}

button[type="submit"]:disabled,
button[type="button"]:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.item label {
  display: inline-block;
  width: 25%;
  margin-right: 1rem;
  margin-bottom: 0;
}

.item input[type="text"],
.item input[type="number"] {
  width: 50%;
}

.item button {
  margin-top: 1rem;
}

.Add-Button {
  margin-bottom: 24px;
}

option {
  padding: 0.5rem;
}