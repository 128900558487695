/* src/components/Login.css */

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 36px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 48px;
}

.App-logo {
  place-self: center;
  height: 36vmin;
  pointer-events: none;
}

h1 {
  margin-bottom: 20px;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input {
  padding: 10px;
  /* margin-bottom: 10px; */
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

input:focus {
  border-color: #6c9ac3;
}

button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #6c9ac3;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  font-size: 18px;
  font-weight: bold;
}

button:hover {
  background-color: #4c7aa1;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

.login-footer {
  margin-top: 64px;
  text-align: center;
  font-size: 14px;
}