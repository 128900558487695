.create-user-container {
  width: 100%;
  max-width: 678px !important;
  margin-left: auto;
  margin-right: auto;
}

.create-user-header {
  text-align: center;
}

.create-user-header p {
  margin-top: 8px;
}

/* h2 {
  font-size: 24px;
  margin-bottom: 20px;
  padding: 16px;
} */

/* #create-user-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

input,
textarea,
select {
  font-size: 16px;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

input {
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  min-height: 200px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.submit-button button {
  font-size: 16px;
  padding: 10px;
  background-color: #0077cc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 30%;
}

button:hover {
  background-color: #005fa3;
} */

@media screen and (max-width: 768px) {
  .submit-button {
    width: inherit !important;
  }
}