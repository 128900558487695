.layouts {
  width: 100vw;
  height: 100vh;
  background-color: rgba(58, 146, 121, 0.05);
  display: flex;
  /* overflow: hidden; */
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.topbar {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
  border-bottom: 1px solid #f4f4f4;
  height: 40px;
}

.content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.sidebar {
  width: 270px;
  background-color: white;
  /* border-right: 1px solid #f4f4f4; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* justify-content: space-between; */
}

.sidebar .logo {
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
  height: 40px;
}

.sidebar .menus {
  flex: 1;
}

.transition {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.icon-up {
  transform: rotate(180deg);
}

.icon-down {
  transform: rotate(0deg);
}

.sidebar .menus li a {
  font-weight: 500;
  color: #b0b0b0;
  padding: 16px;
  cursor: pointer;
}

.submenu {
  font-weight: 500;
  color: #b0b0b0;
  padding: 16px;
  cursor: pointer;
}

.sidebar .menus li .icon {
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.sidebar .menus {
  padding-top: 20px;
}

.sidebar .menus li a:hover,
.sidebar .menus li .active,
.submenu:hover {
  background: rgba(58, 146, 121, 0.05);
  color: #39aab4;
  font-weight: 600;
}

.sidebar .footer {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f4f4f4;
  height: 40px;
}

.sidebar .footer button {
  background-color: unset;
  margin: 0;
  padding: 0;
}

.sidebar .footer .profile {
  display: flex;
  align-items: center;
}

.sidebar .footer .profile .avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  overflow: hidden;
}

.sidebar .footer .profile .avatar img {
  width: 100%;
  background-size: cover;
}

.sidebar .footer .profile h5 {
  font-weight: 600;
  font-size: 14px;
  color: #231f20;
}

.sidebar .footer .profile p {
  font-weight: 400;
  font-size: 12px;
  color: #b0b0b0;
}

.menu-hide {
  max-height: 0px;
  overflow: hidden;
}

.menu-show {
  max-height: 500px;
  overflow: hidden;
}

.topbar button {
  display: none;
}

@media screen and (max-width: 768px) {
  .sidebar {
    position: fixed;
    z-index: 100;
    box-shadow: 0px 5px 10px rgba(160, 163, 189, 0.1);
    transform: translateX(-100%);
    transition: transform 300ms ease-in-out;
  }

  .show-sidebar {
    transform: translateX(0);
  }

  .content {
    position: relative;
  }

  .content-backdrop {
    overflow: hidden;
  }

  .content-backdrop::before {
    content: "";
    position: absolute;
    background: black;
    height: 100vh;
    opacity: 20%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
  }

  .sidebar .logo h2 {
    font-size: 24px;
  }

  .topbar {
    position: relative;
  }

  .topbar button:hover {
    background-color: #41a0a8;
  }

  .topbar button {
    display: inherit;
    position: absolute;
    right: 20px;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #39aab4;
    color: white;
  }

  .main {
    width: 100vw;
    overflow: hidden;
  }


  .content {
    width: 91vw;
  }

  .topbar h2 {
    font-size: 24px;
  }
}