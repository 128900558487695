.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.dashboard-container {
  margin: 0 auto;
  /* max-width: 1000px; */
}

.table-container {
  position: relative;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

input[type="text"] {
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  margin-bottom: 1rem;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 1rem;
  text-align: left;
}

thead tr {
  background-color: #f2f2f2;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

tbody tr:hover {
  background-color: #e5e5e5;
}

th {
  background-color: salmon;
  color: white;
}

.update-button {
  background-color: salmon;
  font-weight: 500;
  font-size: 16px;
}

.update-button:hover {
  background-color: black;
}

.dashboard-searchbar {
  background-color: #f2f2f2;
  border: none;
  border-radius: 20px;
  color: #333;
  padding: 12px 20px;
  width: 100%;
}

.filter_agent {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  width: 300px;
}

@media screen and (max-width: 768px) {

  .gridjs-search,
  .filter_agent,
  .gridjs-search input {
    width: 100% !important;
  }

  .filter_agent {
    top: -50.5px;
  }

  .content {
    padding-top: 70.5px;
  }
}