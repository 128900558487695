/* *,
::before,
::after {
  box-sizing: border-box;
} */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
ul,
li,
a {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: unset;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gridjs-pagination .gridjs-pages button {
  font-weight: 400;
  font-size: 14px;
  border-radius: 0px;
  color: black;
}

.card {
  padding: 24px 20px;
  border-radius: 10px;
  height: fit-content;
  background-color: white;
  box-shadow: 0px 5px 10px rgba(160, 163, 189, 0.1);
}

@media screen and (max-width: 768px) {
  .card {
    padding: 20px;
  }

  .gridjs-pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}

.form-input {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-input label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #B0B0B0;

}

.form-input label span {
  font-size: 12px;
  font-weight: 400;
}

.form-input:has(input:focus) label,
.form-input:has(textarea:focus) label {
  color: #39AAB4;
}

.form-input input,
.form-input textarea {
  padding: 14px 15px;
  font-weight: 500;
  font-size: 14px;
  color: #231F20;
  background: #FFFFFF;
  border: 1px solid #F4F4F4;
  border-radius: 8px;
}

.form-input textarea {
  resize: none;
  width: inherit;
}

.form-input input:focus,
.form-input textarea:focus {
  border: 1px solid #39AAB4;
}

.form-input textarea:focus {
  outline: none;
}

.grid {
  display: grid;
  gap: 10px;
}

.grid-col-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-col-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-col-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.text-error {
  font-size: 12px;
  color: #e33434;
  margin-top: -6px;
}

.form-input:has(.text-error) input,
.form-input:has(.text-error) textarea {
  border: 1px solid #e33434;
}

.form-input:has(.text-error):has(input:focus) label,
.form-input:has(.text-error):has(textarea:focus) label {
  color: #e33434;
}

p {
  color: #B0B0B0;
}

hr {
  border: 0.5px solid #B0B0B0;
}

input,
textarea,
button {
  font-family: 'system-ui';
}

input[type=date]:invalid::-webkit-datetime-edit {
  color: #777777;
}